::placeholder {
  color: rgb(0 0 0 / 30%);
}

form {
  margin: 1.25rem 0;
}

.window {
  display: flex;
  flex-direction: column;
  background: #222;
  color: #fff;
  height: 47vh; /** Trying not to end up with scrollbars on the browser window's viewport */
}

.window .header {
  flex-basis: 3rem;
  background: #c6c6c6;
  position: relative;
}

.window .header:after {
  content: ". . .";
  position: absolute;
  left: 12px;
  right: 0;
  top: -3px;
  font-family: "Times New Roman", Times, serif;
  font-size: 96px;
  color: #fff;
  line-height: 0;
  letter-spacing: -12px;
}

.cursor-disabled {
  cursor: not-allowed;
}
